/* eslint-disable */
/* eslint-disable import/no-unresolved */
import React, { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useParams } from "react-router-dom";
import { StyledLoader } from "src/components/preloader/style";
import { auth, dbWisecrack } from "src/config/firebase";
import { Spinner } from "@doar/components";
import { CustomerContext } from "./userContext";
import { doc, getDoc } from "firebase/firestore";

interface ProtectedRouteProps {
  children: JSX.Element;
  checkChannelOwner?: boolean; // Prop opcional
}

async function isChannelOwner(channelId: string, customer: any) {
  const docRef = doc(dbWisecrack, "channels", channelId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const channelData = docSnap.data();
    if (channelData?.owner_id === customer?.id) {
      return true;
    }
  }
  return false;
}

const ProtectedRoute = ({ children, checkChannelOwner = false }: ProtectedRouteProps) => {
  const { customer } = useContext(CustomerContext);
  const [user, loading] = useAuthState(auth);
  const { id: channelId } = useParams();
  const [isOwner, setIsOwner] = useState<boolean | null>(null); // Estado para manejar la verificación
  const [loadingCustomer, setLoadingCustomer] = useState(true);

  const authed = auth.currentUser != null;

  useEffect(() => {
    if (customer && customer.id !== "defaultCustomer") {
      setLoadingCustomer(false);
    }
  }, [customer]);

  useEffect(() => {
    if (authed && checkChannelOwner && !loadingCustomer && channelId) {
      const verifyOwner = async () => {
        const result = await isChannelOwner(channelId, customer);
        setIsOwner(result); // Actualiza el estado con el resultado
      };
      verifyOwner();
    }
  }, [authed, checkChannelOwner, loadingCustomer, channelId]); // Dependencias para ejecutar cuando cambien estas variables

  if (loading || loadingCustomer) {
    return (
      <StyledLoader>
        <Spinner size="lg" color="primary" />
      </StyledLoader>
    );
  } else {
    const papiUserJSON = sessionStorage.getItem("papiUser");
    let papiUser = null;

    if (papiUserJSON) {
      papiUser = JSON.parse(papiUserJSON);
    }

    if (authed) {
      if (papiUser?.user?.role !== "ADMIN") {
        if (checkChannelOwner) {
          if (isOwner === null) {
            // Mientras se espera el resultado de la verificación
            return (
              <StyledLoader>
                <Spinner size="lg" color="primary" />
              </StyledLoader>
            );
          }

          if (!isOwner) {
            return <Navigate to="/channels" />;
          }
        }
      }

      return customer?.onboarding?.status === "pending" ? <Navigate to="/onboarding" /> : children;
    }

    return <Navigate to="/signin" />;
  }
};

export default ProtectedRoute;
